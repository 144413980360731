import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/reacteev/reacteev-website/src/components/mdx-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MatomoTrackingConfiguration = makeShortcode("MatomoTrackingConfiguration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In accordance with the provisions of Articles 6-III and 19 of French Law No. 2004-575 of June 21, 2004 on Confidence in the Digital Economy, known as L.C.E.N., we bring to users and visitors of the website `}<a parentName="p" {...{
        "href": "https://reacteev.com"
      }}>{`https://reacteev.com`}</a>{` the following information:`}</p>
    <section><h2 parentName="section" {...{
        "id": "description-of-the-services-provided"
      }}>{`Description of the services provided`}</h2><p parentName="section">{`The purpose of the ”`}<a parentName="p" {...{
          "href": "https://reacteev.com"
        }}>{`https://reacteev.com`}</a>{`” website is to provide information about all of the company’s activities. The site owner strives to provide information on this site that is as accurate as possible. However, it cannot be held responsible for omissions, inaccuracies and shortcomings in updating, whether these are its fault or the fault of third-party partners who provide this information. All information provided on the `}<a parentName="p" {...{
          "href": "https://reacteev.com"
        }}>{`https://reacteev.com`}</a>{` website is given for information purposes only, is not exhaustive, and is subject to change. It is subject to any changes that may have been made since it was put online.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "intellectual-property-and-counterfeiting"
      }}>{`Intellectual property and counterfeiting`}</h2><p parentName="section">{`The owner of the site is the owner of the intellectual property rights or holds the rights of use on all the elements accessible on the site, in particular the texts, images, graphics, logo, icons, sounds, software… Any reproduction, representation, modification, publication, total or partial adaptation of the elements of the site, whatever the means or the process used, is prohibited, except prior written authorization by email to `}<a parentName="p" {...{
          "href": "mailto:contact@reacteev.com"
        }}>{`contact@reacteev.com`}</a>{`. Any unauthorised use of the site or any of the elements it contains will be considered as constituting an infringement and will be prosecuted in accordance with the provisions of articles L.335-2 and following of the French Intellectual Property Code.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "data-privacy"
      }}>{`Data Privacy`}</h2><p parentName="section">{`On this site, the owner of the site collects personal information relating to the user only for the need of certain services offered by the site: Contact us & Make an appointment. The user provides this information with full knowledge of the facts, particularly when he/she enters it himself/herself. Reacteev therefore undertakes the obligation not to provide this information. In accordance with the provisions of Articles 38 and following of French Law 78-17 of January 6, 1978 relating to data processing, files and freedoms, any user has a right of access, rectification, deletion and opposition on personal data concerning him/her. To exercise this right, send your request to `}<a parentName="p" {...{
          "href": "mailto:dpo@reacteev.com"
        }}>{`dpo@reacteev.com`}</a>{`.`}</p><section parentName="section"><h3 parentName="section" {...{
          "id": "opt-out-of-website-tracking"
        }}>{`Opt-out of website tracking`}</h3><MatomoTrackingConfiguration mdxType="MatomoTrackingConfiguration" /></section></section>
    <section><h2 parentName="section" {...{
        "id": "publisher"
      }}>{`Publisher`}</h2><p parentName="section">{`Reacteev`}<br parentName="p"></br>{`
`}{`SAS with a capital of 3 300 Euros`}<br parentName="p"></br>{`
`}{`SIREN: 811144062 RCS Paris`}<br parentName="p"></br>{`
`}{`Head office: 5 Rue du Mail, 75002 Paris, France`}<br parentName="p"></br>{`
`}{`VAT N°: FR 85 811144062`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "director-of-publication"
      }}>{`Director of publication`}</h2><p parentName="section">{`Mr. Nicolas Morin, Managing Director of Reacteev.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "hosting"
      }}>{`Hosting`}</h2><p parentName="section">{`SCALEWAY SAS`}<br parentName="p"></br>{`
`}{`BP 438`}<br parentName="p"></br>{`
`}{`75366 Paris Cedex 08`}<br parentName="p"></br>{`
`}{`France`}</p></section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      